<template>
  <div
    class="input"
    :class="computedData.class"
    :style="computedData.style"
    :id="computedID"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
  >
    <div
      ref="wrapper"
      class="input__wrapper"
      :class="computedBackground.class"
      :style="computedBackground.style"
    >

      <slot name="prepend-outer" v-bind="scope"/>

      <div
        v-if="hasLabel"
        class="input__label"
        :style="labelCorrection"
        @click="onClickSlot('label',$event)"
      >
        <slot name="label" v-bind="scope">
          <label :for="computedID">{{ label }}</label>
        </slot>
      </div>

      <div
        v-if="hasPrepend"
        ref="prepend"
        class="input__prepend"
        :style="computedHeight"
        @click="onClickSlot('prepend',$event)"
        @mouseup="onMouseUpSlot('prepend',$event)"
      >

        <slot name="prepend" v-bind="scope">
          <v-icon
            v-text="prependIcon"
            :color="validationState"
            :dark="dark"
            :light="light"
            :aria-label="ariaLabel('prepend')"
          />
        </slot>

      </div>
      <div
        ref="content"
        class="input__content"
        :style="{ minHeight: computedHeight.height }"
        @click="onClickSlot( 'content', $event )"
      >

        <div
          v-if="prefix"
          ref="prefix"
          class="input__prefix"
          :style="{ height: computedHeight.height }"
        >
          {{ prefix }}
        </div>

        <slot v-bind="scope"/>

        <div
          v-if="sufix"
          class="input__sufix"
          :style="{ height: computedHeight.height }"
        >
          {{ sufix }}
        </div>

      </div>
      <div
        ref="append"
        v-if="hasAppend || ( clearable && isDirty )"
        class="input__append"
        :style="computedHeight"
        @click="onClickSlot('append',$event)"
      >

        <v-icon v-if="clearable && isDirty" class="input__clear" @click="clearableCallback">
          {{ clearIcon }}
        </v-icon>

        <slot name="append" v-bind="scope">
          <v-icon
            v-text="appendIcon"
            :color="validationState"
            :dark="dark"
            :light="light"
            :aria-label="ariaLabel('append')"
          />
        </slot>

      </div>

      <v-progress-linear
        v-if="loading"
        class="input__loader"
        :color="( loading === true || loading === '' ) ? validationState : loading"
        :height="loaderHeight"
        indeterminate
        absolute
      />

    </div>

    <slot name="append-outer" v-bind="scope"/>

    <div
      v-if="showDetails"
      class="input__details"
      @mousedown="onMouseDownDetails"
    >

      <v-messages
        :value="messagesToDisplay"
        :color="!hasHint ? validationState : ''"
        :dark="dark"
        :light="light"
        :role="hasMessages ? 'alert' : null"
      >
        <slot name="message" v-bind="scope"/>
      </v-messages>

      <slot
        v-if="hasCounter"
        v-bind="scope"
        name="counter"
      >
        <v-counter
          :value="computedCounterValue"
          :dark="dark"
          :light="light"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import bindsattrs from 'vuetify/lib/mixins/binds-attrs';
import validatable from 'vuetify/lib/mixins/validatable';
import colorable from 'vuetify/lib/mixins/colorable';
import themeable from 'vuetify/lib/mixins/themeable';
import elevatable from 'vuetify/lib/mixins/elevatable';
import colors from 'vuetify/lib/util/colors';
import { classToHex, isCssColor } from 'vuetify/lib/util/colorUtils';
import { convertToUnit, kebabCase } from 'vuetify/lib/util/helpers';

export default {
  inheritAttrs: false,
  mixins: [ bindsattrs, validatable, colorable, themeable, elevatable ],
  props: {
    value: null,
    prefix: String,
    sufix: String,
    id: String,
    singleLine: Boolean,
    outlined: Boolean,
    rounded: Boolean,
    solo: Boolean,
    tile: Boolean,
    filled: Boolean,
    appendIcon: String,
    prependIcon: String,
    hideDetails: [ Boolean, String ],
    hint: String,
    id: String,
    label: String,
    loading: [ Boolean, String ],
    loaderHeight: {
      type: [ Number, String ],
      default: 2
    },
    persistentHint: Boolean,
    counter: [ Boolean, Number, String ],
    counterValue: Function,
    height: {
      type: [ Number, String ],
      default: 30
    },
    elevation: {
      type: [ Number, String ],
      default: 1
    },
    backgroundColor: String,
    borderColor: String,
    clearable: Boolean,
    clearIcon: {
      type: String,
      default: '$clear',
    }
  },
  data() {
    return {
      lazyValue: this.value,
      hasMouseDown: false,
      prependWidth: 0,
      labelPosition: 0
    };
  },
  computed: {
    classNames() {
      return {
        '--has-state': this.hasState,
        '--has-prepend': this.hasPrepend,
        '--has-append': this.hasAppend,
        '--has-label': this.hasLabel,
        '--has-prepend-handler': this.hasPrependHandler,
        '--has-append-handler': this.hasAppendHandler,
        '--hide-details': !this.showDetails,
        '--single-line': !this.isSingleLine,
        '--outlined': this.outlined,
        '--rounded': this.rounded || this.solo,
        '--tile': this.tile,
        '--solo': this.solo,
        '--filled': this.filled,
        '--dirty': this.isDirty,
        '--disabled': this.isDisabled,
        '--focused': this.isFocused,
        '--loading': this.loading !== false && this.loading != null,
        '--readonly': this.isReadonly,
        ...this.themeClasses
      }
    },
    computedID() {
      return this.id || `input-${this._uid}`;
    },
    scope() {
      return {
        value: this.lazyValue,
        readonly: this.isReadonly,
        focused: this.focused,
        dirty: this.isDirty,
        //active: this.isLabelActive,
        disabled: this.isDisabled,
        height: this.computedHeight.height,
        counter: this.computedCounterValue,
        maxCount: this.computedCounterMax
      }
    },
    computedHeight() {
      return { height: convertToUnit( this.height )};
    },
    computedData() {
      return this.setTextColor( this.validationState, {
        class: this.classNames
      });
    },
    computedBackground() {

      let data = {
        style: {},
        class: {
          'input__wrapper': true,
          ...this.elevationClasses
        }
      };

      if ( this.filled )
        data = this.setBackgroundColor( this.backgroundColor || 'grey lighten-3', data );

      if ( this.outlined )
        data = this.setBorderColor( this.validationState || this.borderColor || '#CCCCCC', data );

      return data;
    },
    hasDetails() {
      return this.messagesToDisplay.length > 0;
    },
    hasHint() {
      return !this.hasMessages && !!this.hint && (this.persistentHint || this.isFocused);
    },
    hasLabel() {
      return !!(this.$slots.label || this.label);
    },
    hasPrepend() {
      return !!(this.$slots.prepend || this.prependIcon);
    },
    hasAppend() {
      return !!(this.$slots.append || this.appendIcon);
    },
    hasPrependHandler() {
      return !!this.listeners$[`click:prepend`];
    },
    hasAppendHandler() {
      return !!this.listeners$[`click:append`];
    },
    showDetails() {
      return this.hideDetails === false || this.hideDetails === 'auto' && this.hasDetails;
    },
    hasCounter() {
      return this.counter !== false && this.counter != null;
    },
    computedCounterValue() {
      if ( typeof this.counterValue === 'function' ) {
        return this.counterValue( this.internalValue );
      }
      return ( this.internalValue || '' ).toString().length;
    },
    computedCounterMax() {
      return this.counter === true ? this.attrs$.maxlength : this.counter;
    },
    isDirty() {
      return this.lazyValue != null;
    },
    isSingleLine() {
      return this.singleLine === true || ( ! this.isDisabled && ( this.isDirty || this.isFocused ));
    },
    messagesToDisplay() {
      if ( this.hasHint ) return [ this.hint ];
      if ( ! this.hasMessages ) return [];
      return this.validations.map( validation => {
        if ( typeof validation === 'string' ) return validation;
        const validationResult = validation( this.internalValue );
        return typeof validationResult === 'string' ? validationResult : '';
      }).filter( Boolean );
    },
    labelCorrection() {
      return {
        left: ! this.isSingleLine
          ? convertToUnit( this.prependWidth + this.labelPosition )
          : null
        };
    },
  },
  watch: {
    prependIcon: 'getPrependWidth',
    prefix: 'getPrependWidth',
    value( value ) {
      this.internalValue = value;
    },
    isFocused( value ) {
      this.hasColor = value;
      this.$emit( 'focused', value );
    }
  },
  methods: {
    getPrependWidth() {
      requestAnimationFrame(() => {
        this.prependWidth = this.$refs.prepend ? this.$refs.prepend.scrollWidth : 0;
        this.prependWidth += this.$refs.prefix ? this.$refs.prefix.scrollWidth : 0;
      });
    },
    setBorderColor( color, data ) {

      if ( ! isCssColor( color )) {
        color = classToHex( color, colors, this.isDark
          ? this.$vuetify.theme.themes.dark
          : this.$vuetify.theme.themes.light );
      }

      if ( color ) {
        data = data || {};
        data.style = data.style || {};
        data.style.borderColor = color;
      }

      return data;
    },
    hasListener( type ) {
      return !!this.listeners$[`click:${type}`];
    },
    onClick(e) {
      this.$emit('click', e);
    },
    onMouseDown(e) {
      this.hasMouseDown = true;
      this.$emit('mousedown', e);
    },
    onMouseUp(e) {
      this.hasMouseDown = false;
      this.$emit('mouseup', e);
    },
    onMouseUpSlot( type, event ) {
      const eventName = `click:${type}`;
      if ( this.listeners$[eventName]) {
        this.prevent( event );
      }
    },
    onMouseDownDetails(e) {
      this.hasMouseDown = false;
      e.stopPropagation();
    },
    onClickSlot( type, event ) {
      const eventName = `click:${type}`;
      if ( this.listeners$[eventName]) {
        this.prevent( event );
        this.$emit( eventName, event );
      }
    },
    prevent( event ) {
      event.preventDefault();
      event.stopPropagation();
    },
    ariaLabel( type ) {
      return this.listeners$[`click:${type}`]
        ? `${type} icon`
        : undefined;
    },
    clearableCallback( event ) {
      event.stopPropagation();
      this.$emit( 'click:clear', event );
      this.$nextTick(() => this.internalValue = undefined );
    }
  },
  mounted() {
    this.getPrependWidth();
  }
}
</script>

<style>
.input {
  font-size: .875rem;
  margin-bottom: .5em;
}
.input.--has-label {
  margin-top: 1.2em;
}
.input__wrapper {
  border-radius: 4px;
  padding: 0 .5em;
}
.input__wrapper,
.input__content {
  position: relative;
  height: inherit;
  display: flex;
  flex-wrap: nowrap;
}
.input__prepend,
.input__append {
  height: inherit;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}
.input__prepend > *,
.input__append > * {
  color: inherit !important;
  font-size: 1.5em !important;
}
.input.--has-prepend-handler .input__prepend > * {
  cursor: pointer;
}
.input.--has-append-handler .input__append > * {
  cursor: pointer;
}
.input__content {
  flex: 1 0 auto;
  min-height: 100%;
}
.input__details {
  margin-top: .5em;
  padding: 0 .5em;
  display: flex;
}
.input__details > .v-messages {
  padding: 0;
  flex: 1 1 auto;
}
.input:not(.--solo) .input__wrapper {
  box-shadow: none !important;
}
.input__label {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  color: currentColor;
  height: 0;
  bottom: 100%;
  margin: .5em;
  transform: scale(.85);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transform-origin: left 50%;
  cursor: text;
}
.input__label > * {
  cursor: inherit;
}
.input.--rounded .input__wrapper,
.input.--rounded .input__details {
  padding: 0 .75em;
}
.input.--rounded .input__label,
.input.--outlined .input__label,
.input.--filled .input__label {
  margin: .75em .5em;
}
.input.--rounded .input__label {
  margin: .75em;
}
.input.--single-line .input__label {
  transform: scale(1);
  height: 100%;
  bottom: 0;
  margin: 0 .5em;
}
.input.--rounded.--single-line .input__label {
  margin: 0 .75em;
}
.input:not(.--solo):not(.--loading) .input__wrapper {
  border-bottom: 1px solid currentColor;
}
.input:not(.--outlined):not(.--solo):not(.--filled) .input__wrapper {
  border-radius: 0;
}
.input.--tile .input__wrapper {
  border-radius: 0px;
}
.input.--outlined .input__wrapper {
  border: 1px solid currentColor;
}
.input.--rounded .input__wrapper {
  border-radius: 9999px;
  padding-left: .75em;
  padding-right: .75em;
}
.input.--solo .input__wrapper {
  border: 0;
}
.input.theme--light {
  color: black;
}
.input.theme--light.--filled .input__wrapper {
  background-color: white;
}
.input.theme--light.--single-line:not(.--dirty) .input__label {
  color: #777;
}
.input.theme--light.--disabled {
  color: #CCC;
}
.input.theme--dark {
  color: white;
}
.input.theme--dark.--filled .input__wrapper {
  background-color: black;
}
.input.theme--dark.--single-line:not(.--dirty) .input__label {
  color: #CCC;
}
.input.theme--dark.--disabled {
  color: #777;
}
.input__clear {
  font-size: 1em !important;
  margin-right: .25em;
}
.input__loader {
  width: 100%;
  bottom: 0;
}

.input input {
  height: 100%;
  flex: 1 1 auto;
  max-width: 100%;
}
.input input:focus {
  border: 0;
  outline: 0;
}
.input__prefix,
.input__sufix {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  padding: 0 .25em;
}
.input.theme--light .input__prefix,
.input.theme--light .input__sufix,
.input.theme--light input {
  color: black;
}
.input.theme--dark .input__prefix,
.input.theme--dark .input__sufix,
.input.theme--dark input {
  color: white;
}
.input.theme--light.--disabled input,
.input.theme--light.--disabled .input__prefix,
.input.theme--light.--disabled .input__sufix,
.input.theme--light.--disabled input,
.input.theme--dark.--disabled input,
.input.theme--dark.--disabled .input__prefix,
.input.theme--dark.--disabled .input__sufix,
.input.theme--dark.--disabled input {
  color: inherit;
}
.input.theme--light input::placeholder,
.input.theme--light:not(.--dirty) .input__prefix,
.input.theme--light:not(.--dirty) .input__sufix {
  color: #777;
  opacity: 1;
}
.input.theme--dark input::placeholder {
  color: #CCC;
  opacity: 1;
}
</style>
