<template>
  <input-component
    ref="field"
    class="textfield"
    v-bind="$attrs"
    v-on="listeners"
    :value="internalValue"
    :single-line="!!placeholder || singleLine"
  >
    <template v-slot="props">
      <input
        ref="input"
        :type="type"
        :value="internalValue"
        v-bind="inputProps"
        @input="onInput( $event.target.value )"
        @focus="focus"
        @blur="blur"
        @keydown="onKeyDown"
        @keyup="$emit( 'keyup', $event )"
        @keypress="$emit( 'keypress', $event )"
      />
    </template>

    <template v-for="name in slots" v-slot:[name]>
      <slot :name="name"/>
    </template>

  </input-component>
</template>

<script>
import InputComponent from './Input';
import { slots, createEvents, clickEvent } from './utils';
import { getObjectValueByPath } from 'vuetify/lib/util/helpers';

export default {
  inheritAttrs: false,
  components: { InputComponent },
  props: {
    value: null,
    placeholder: String,
    autofocus: Boolean,
    singleLine: Boolean,
    preventKey: Function,
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      slots,
      internalValue: this.value || undefined
    }
  },
  computed: {
    inputProps() {

      const name = getObjectValueByPath( this.$attrs, 'name', null );
      const autocomplete = getObjectValueByPath( this.$attrs, 'autocomplete', 'off' );
      const readonly = this.$refs.field && this.$refs.field.isReadonly;

      return {
        placeholder: this.placeholder,
        'aria-readonly': readonly && String( readonly ),
        name: name || autocomplete !== 'off' ? autocomplete : null,
        autocomplete,
        readonly
      }
    },
    listeners() {
      return createEvents( this, {
        'click:label': clickEvent( this, 'click:label' ),
        'click:append': clickEvent( this, 'click:append' ),
        'click:prepend': clickEvent( this, 'click:prepend' ),
        'click:clear': clickEvent( this, 'click:clear' ),
        input: this.onInput,
        focus: null,
        blur: null
      });
    }
  },
  watch: {
    value: 'setValue'
  },
  methods: {
    setValue( value ) {
      this.internalValue = value || undefined;
    },
    onClear(e) {
      this.focus();
      this.onInput();
      this.$emit( 'click:clear', e );
    },
    onInput( value ) {
      this.setValue( value );
      this.$emit( 'input', value );
    },
    tryAutofocus() {
      if (
        !this.autofocus ||
        typeof document === 'undefined' ||
        !this.$refs.input ||
        document.activeElement === this.$refs.input
      ) return false;
      this.focus();
      return true;
    },
    onKeyDown( event ) {
      this.$emit( 'keydown', event );
      if ( this.preventKey && this.preventKey( event )) {
        event.preventDefault();
        return false;
      }
    },
    /** @public */
    focus( event ) {
      this.$refs.input && this.$refs.input.focus();
      this.$refs.field && ( this.$refs.field.isFocused = true );
      event && this.$emit( 'focus', event );
    },
    /** @public */
    blur( event ) {
      if ( this.$refs.field && this.$refs.field.hasMouseDown ) return;
      this.$refs.field && (this.$refs.field.isFocused = false);
      this.$refs.input && this.$refs.input.blur();
      event && this.$emit( 'blur', event );
    }
  },
  mounted() {
    this.tryAutofocus();
  }
};
</script>
