import moment from 'moment';

export const slots = [
  'label',
  'prepend',
  'prepend-outer',
  'append',
  'append-outer',
  'message',
  'counter'
];

export const events = [
  'click',
  'click:label',
  'click:append',
  'click:prepend',
  'click:clear',
  'click:content',
  'mousedown',
  'mouseup',
  'input',
  'focused',
  'focus',
  'blur'
]

export const createEvents = ( ctx, replace = {} ) => {
  var a = {};
  events.forEach( event => {
    if ( event in replace ) replace[event] !== null && ( a[event] = replace[event] );
    else a[event] = e => ctx.$emit( event, e );
  });
  return a;
}

export const clickEvent = ( ctx, name ) => event => {
  ctx.focus && ctx.focus();
  ctx.$emit( name, event );
}

export const countChars = ( str, chars ) => {
  return (( str || '').match( new RegExp( `[${chars}]`, 'g' )) || '' ).length;
}

export const getMoment = ( value, format ) => {

  format = format || 'YYYY-MM-DD';
  if ( value == null ) return null;
  var m;

  if ( typeof value === 'string' ) {
    m = moment( value, format ).startOf('day').add( 12, 'h' );
    if ( m.format( format ) !== value ) return null;
  } else {
    m = moment( value ).startOf('day').add( 12, 'h' );
  }

  if ( ! m.isValid()) return null;
  return m;
}
